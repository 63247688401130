import * as React from 'react'
import { graphql } from 'gatsby'
import { useQueryParam, ArrayParam, withDefault } from 'use-query-params'
import PropTypes from 'prop-types'

import Layout from '../../components/layout'

import PortfolioList from '../../components/portfolio/list'
import PortfolioFilters from '../../components/portfolio/filters'

const PortfolioPage = ({ data }) => {
	const portfolio = data.strapi.portfolioPage
	const categories = data.strapi.categories
	const drafts = data.strapi.projects

	// Active State for Enabled Filters
	const [active, setActive] = useQueryParam('cat', withDefault(ArrayParam, []))

	// Add Fitlers to Active Array
	const toggleActive = filter => {
		setActive(prevState => {
			let nextState = [...prevState]

			if (!prevState.includes(filter)) {
				nextState.push(filter)
			} else {
				nextState = nextState.filter(state => state !== filter)
			}

			return nextState
		})
	}

	return (
		<Layout page={portfolio} metadata={portfolio.metadata}>
			<div className="container pt-80 sm:pt-120 lg:pt-0">
				{/* Headline Section */}
				<section className="py-20 lg:py-40">
					<h1 className="w-10/12">{portfolio.headline}</h1>
				</section>

				{/* Portfolio Category Filters */}
				<section className="pb-40 sm:pb-60 md:pb-80 lg:pb-100 xl:pb-120">
					<PortfolioFilters
						filters={categories}
						toggle={toggleActive}
						active={active}
						all={false}
					/>
				</section>

				{/* Small Project Cards in Order */}
				<section className="pb-40 sm:pb-60 md:pb-80 lg:pb-100 xl:pb-120">
					<PortfolioList order={drafts} active={active} />
				</section>
			</div>
		</Layout>
	)
}

PortfolioPage.propTypes = {
	data: PropTypes.shape({
		strapi: PropTypes.shape({
			portfolioPage: PropTypes.shape({
				headline: PropTypes.string.isRequired,
				order: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
				metadata: PropTypes.shape({}).isRequired,
			}).isRequired,
			categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
		}).isRequired,
	}).isRequired,
}

export const query = graphql`
	{
		strapi {
			projects(publicationState: PREVIEW, sort: "updated_at:desc") {
				slug
				title
				subtitle
				industries {
					slug
					title
				}
				categories {
					slug
					title
				}
				thumbnail {
					url
					ext
					height
					width
					name
					imageFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								width: 360
								height: 270
								transformOptions: { cropFocus: CENTER, fit: COVER }
								layout: CONSTRAINED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
				}
			}
			portfolioPage {
				headline
				footer_headline
				footer_link_url
				footer_link_text
				metadata {
					meta_title
					meta_desc
					meta_image {
						url
						imageFile {
							publicURL
						}
					}
				}
			}
			categories {
				slug
				title
			}
		}
	}
`

export default PortfolioPage
